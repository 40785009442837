import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import {Breadcrumb,Container,Row,Col} from "react-bootstrap";
import "./Breadcrumbs.scss"

import { ABOUT_PAGE_URL, CASE_STUDIES_PAGE_URL } from "../../common/site/constants"


// markup
const CaseStudiesGuideBreadcrumbs = (props ) => {

  return ( 
        <React.Fragment>
           <div className="breadcrumb-wrap">
           <Container>
             <Row>
               <Col md='12'>
                  <Breadcrumb>
                    <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                      {/* <Breadcrumb.Item><Link to={`${ABOUT_PAGE_URL.alias}`}>{ABOUT_PAGE_URL.name}</Link></Breadcrumb.Item> */}
                      <Breadcrumb.Item><Link to={`${CASE_STUDIES_PAGE_URL.alias}`}>{CASE_STUDIES_PAGE_URL.name}</Link></Breadcrumb.Item>
                    {/* <Breadcrumb.Item href="#">Areas we cover</Breadcrumb.Item> */}
                    <Breadcrumb.Item active>{props.Pagename}</Breadcrumb.Item>
                  </Breadcrumb>
               </Col>
             </Row>
           </Container>
           </div>
        </React.Fragment>
    )
}
export default CaseStudiesGuideBreadcrumbs
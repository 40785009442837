import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col, Container, Card } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import Slider from "react-slick";
import parse from 'html-react-parser';

import { NewsDate } from "../common/site/functions"


function MoreCaseStudies(props) {

    const settings = {
        dots: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 2,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <React.Fragment>
            <section className="guides-section-list more-similar-news">
                <Container>
                    <Row className="head">
                        <Col lg={12}>
                            <h2>Read more case studies...</h2>
                        </Col>
                    </Row>

                    <Slider {...settings} className="guide-tile-slide">
                        {props.guides && props.guides.map((item, i) => {
                            const image_url = item.Tile_Image?.url;

                            let processedImages = JSON.stringify({});
                            if (item.imagetransforms?.Tile_Image_Transforms) {
                                processedImages = item.imagetransforms.Tile_Image_Transforms;
                            }
                            return (
                                <Link to={`../${item.URL}`}>
                                    <div className="guide">
                                        <div className="img-zoom">
                                            <ImageTransform
                                                imagesources={image_url}
                                                renderer="srcSet"
                                                imagename="case-studies.Tile_Image.tile_1"
                                                attr={{ alt: item.Tile_Image.alternativeText, class: '' }}
                                                imagetransformresult={processedImages}
                                                id={item.id}
                                            />
                                            {/* <img src={item.Tile_Image.url} alt={item.Tile_Image.alternativeText} /> */}
                                        </div>
                                        <p className="title"><span>{item.Title}</span></p>
                                        <p className="date">{item.Address}</p>
                                    </div>
                                </Link>
                            )
                        })}
                    </Slider>

                </Container>
            </section>
        </React.Fragment>
    )
}

export default MoreCaseStudies;


// TODO: Get all the URLs/alias from Strapi and use it here
export const URLS = {
    LATEST_NEWS: {
      name: 'News',
      alias: '/news',
    },
    AREA_GUIDE_LANDING: {
      name: 'Areas We Cover',
      alias: '/about-wards-of-kent/area-guide',
    },
    ABOUT: {
      name: 'About',
      alias: '/about-wards-of-kent',
    },
    CASE_STUDIES: {
      name: 'Case Studies',
      alias: '/our-case-studies',
    },
    VALUATION: {
      name: 'Property Valuation',
      alias: '/sell-your-property/property-valuation',
    },
    FIND_A_PROPERTY: {
      name: 'Find a Property',
      alias: '/find-a-property',
    },
    NEW_HOMES_SEARCH: {
      name: 'Find a Property',
      alias: '/new-homes-for-sale',
    },
    NETWORK: {
      name: 'Estate Agent Network',
      alias: '/estate-agent-network',
    },
    CONTACT: {
      name: 'Contact',
      alias: '/contact',
    }
}


export const LATEST_NEWS_PAGE_URL = URLS.LATEST_NEWS;
export const AREA_GUIDE_PAGE_URL = URLS.AREA_GUIDE_LANDING;
export const ABOUT_PAGE_URL = URLS.ABOUT;
export const CASE_STUDIES_PAGE_URL = URLS.CASE_STUDIES;
export const VALUATION_PAGE_URL = URLS.VALUATION;
export const FIND_A_PROPERTY_PAGE_URL = URLS.FIND_A_PROPERTY;
export const NETWORK_PAGE_URL = URLS.NETWORK;
export const NEW_HOMES_SEARCH_PAGE_URL = URLS.NEW_HOMES_SEARCH;
export const CONTACT_PAGE_URL = URLS.CONTACT;
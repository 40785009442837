import { Link } from "gatsby"
import * as React from "react"
import parse from 'html-react-parser';
import ScrollAnimation from 'react-animate-on-scroll';
import { Button, Container, Row, Col, Form } from "react-bootstrap"
import GenerateLink from "../../common/site/generate-link"

// import deskbanner from "../../../images/offplan/desk-banner.png";
import "./BookValuation.scss"
import { inViewOptions, contentItemStagger, contentItem, fadeInFromTop } from '../../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
// markup
const BookValuation = (props) => {
    return (
        <InView {...inViewOptions}>
          {({ ref, inView }) => (
            <motion.section
              className="book-valuation components--Home--BookValuation--BookValuation"
              ref={ref}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={contentItemStagger}
              
            >
                <Container>
                    <Row className="main-row">
                        <Col lg="12">
                        <div className="register-interest-form-block"> 
                            <motion.div key={1} custom={1} variants={fadeInFromTop} className="valuation-block">
                                {props.Content &&
                                <ScrollAnimation animateIn="fadeInUp" animateOnce>
                                    {parse(props.Content)}
                                </ScrollAnimation>
                                }
                            </motion.div>
                            <ScrollAnimation animateIn="fadeInUp" animateOnce>
                            <div className="btn-row">
                                
                            {props.CTA_1_Label && props.Help_CTA_1_Link &&
                            
                            <motion.div key={2} custom={2} variants={fadeInFromTop}>
                                <GenerateLink link={props.Help_CTA_1_Link} class="btn btn-secondary">
                                    {props.CTA_1_Label}<i className="icon-arrow"></i>
                                </GenerateLink>
                            </motion.div>
                            }   

                            {props.CTA_2_Label && props.CTA_2_Link &&
                            <motion.div key={3} custom={3} variants={fadeInFromTop}>
                                <GenerateLink link={props.CTA_2_Link} class="btn btn-secondary">
                                    {props.CTA_2_Label}<i className="icon-arrow"></i>
                                </GenerateLink>
                            </motion.div>
                            }

                            </div>
                            </ScrollAnimation>
                        </div>
                        </Col>
                    </Row>
                </Container>
          </motion.section>
			)}
		</InView>
    )
}

export default BookValuation
